<template>
  <Spinner v-if="isLoadingOrder" />

  <div v-else class="order-create">
    <h3 class="mb-16">
      {{ isEdit ? `Редактировать заказ № ${formData.account_number}` : 'Создать заказ' }}
    </h3>

    <div class="wrap-form mb-40">
      <VForm @request="submitCreateOrder" v-model="formData">
        <div class="form mb-40">
          <div class="form__col">
            <VInput
              v-if="isAdmin"
              name="external_id"
              label="Внешний ID"
            />

            <VInput
              name="account_number"
              label="№ счета:"
              required
              :disabled="isDisabledForExternal"
            >
              <Validation for="required"/>
            </VInput>

            <VInput
              label="Компания / Контакт:"
              name="client_name"
              required
              :disabled="isDisabledForExternal"
            >
              <Validation for="required"/>
            </VInput>

            <VInput
              label="Город:"
              name="city"
              :pattern="/^[\sА-Яа-я\-(),.]*$/i"
              required
              :disabled="isDisabledForExternal"
            >
              <Validation for="required"/>
              <Validation for="pattern">Только кириллица и символы ( ) , . -</Validation>
            </VInput>

            <VSelect
              label="Тип заказа:"
              name="type"
              :options="propertiesAll.order_type"
              :disabled="isDisabledForExternal"
            />

            <VSelect
              label="Область применения:"
              name="application_area"
              :options="propertiesAll.application_area"
              required
              :disabled="isDisabledForExternal"
            >
              <Validation for="required"/>
            </VSelect>
          </div>

          <div class="form__col">
            <VInput
              label="Назначение пружины:"
              name="appointment"
              required
              :disabled="isDisabledForExternal"
            >
              <Validation for="required"/>
            </VInput>

            <VInput
              label="Связанный заказ (№ счета):"
              name="relation_order_text"
              :loading="isLoading"
              :disabled="isDisabledForExternal"
              @changeValue="checkRelationOrders"
            />

            <VSelect
              label="Менеджер по продажам:"
              name="manager"
              required
              :options="salesManagerList"
              :disabled="isDisabledForExternal"
            >
              <Validation for="required"/>
            </VSelect>

            <VSelect
              label="Инженер-технолог:"
              name="tech_manager"
              required
              :options="processEngineerList"
              :disabled="isDisabled"
            >
              <Validation for="required"/>
            </VSelect>
          </div>

          <div class="form__col">
            <VSelect
              label="Сроки изготовления:"
              name="timing"
              required
              :options="timings"
              :disabled="isDisabledForExternal && !isReclamationOrder"
              @change="changeDateFinished"
            >
              <Validation for="required"/>
            </VSelect>

            <VInput
              label="Дата отгрузки заказа:"
              disabled
              :value="formData.date_finished | date"
            >
              <Validation for="required"/>
            </VInput>

            <VInput
              label="Диск:"
              name="google_link"
              :pattern="/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g"
              :disabled="isDisabledForExternal"
            >
              <Validation for="pattern">Поле формата https://www.google.com</Validation>
            </VInput>
          </div>
        </div>

        <div class="mb-20">
          <VSelect
            v-if="isEdit && permissionUpdateOrderReasonForDelayByRole"
            label="Причина просрочки заказа"
            class="mb-30"
            width="49%"
            placeholder="Выбрать"
            name="delay_reason"
            :options="delayReasons"
            item-text="name"
            :disabled="isDisabled"
          />

          <div class="d-grid d-grid--columns-2 gap-xs">
            <VInput
              type="textarea"
              label="Примечание к причине просрочки"
              name="note_production"
              :disabled="isDisabled"
            />

            <VInput
              type="textarea"
              label="Примечание для тех. отдела"
              name="note_to"
              :disabled="isDisabledForExternal && !isReclamationOrder"
            />

            <VInput
              type="textarea"
              label="Примечание для производства"
              name="note_manufacture"
              :disabled="isDisabledForExternal && !isReclamationOrder"
            />

            <VInput
              type="textarea"
              label="Примечание для ОТК"
              name="note_otk"
              :disabled="isDisabledForExternal && !isReclamationOrder"
            />

            <VInput
              type="textarea"
              label="Примечание для склада"
              name="note_technologist"
              :disabled="isDisabledForExternal && !isReclamationOrder"
            />
          </div>
        </div>

        <div class="buttons">
          <VButton
            :text="isEdit ? 'Сохранить' : 'Создать'"
            color="primary"
            size="xl"
            type="submit"
            :loading="loading"
            :disabled="isAwaitGetRelationOrders"
          />
          <VButton
            text="Отмена"
            size="xl"
            @click.native="goToPrevPage"
          />
        </div>
      </VForm>
    </div>
  </div>
</template>

<script>
import Validation from '@/components/Form/Validation'
import VSelect from '@/components/Form/VSelect'
import VInput from '@/components/Form/Vinput/VInput'
import VForm from '@/components/Form/VForm'
import VButton from '@/components/simple/button/VButton'

import { mapActions, mapGetters, mapState } from 'vuex'

import { getAll } from '@/api/request'

import { goToPage, goToPrevPage } from '@/utils/router'
import { getUserFullName } from '@/utils/model/User'
import { prepareFormDataId } from '@/utils/form/prepareFormDataId'
import OrderController from '@/controllers/production/Order/OrderController'
import { mixinTimeout } from '@/mixins/mixinTimeout'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { splitRelationOrders } from '@/utils/model/Order'

export default {
  name: 'OrderCreationAndEditing',
  components: { VInput, Validation, VSelect, VForm, VButton },
  mixins: [mixinTimeout, mixinIsLoading],
  data: () => ({
    loading: false,
    formData: {
      date_finished: null
    },
    isEdit: false,

    isDisabled: false,
    dataRelationOrder: null,
    isAwaitGetRelationOrders: false,
    relationOrdersResponse: []
  }),

  computed: {
    ...mapState('orderDetail', {
      isLoadingOrder: 'orderLoading'
    }),
    ...mapGetters({
      orderById: 'orderDetail/order',
      permissionUpdateOrderReasonForDelayByRole: 'permission/permissionUpdateOrderReasonForDelayByRole'
    }),
    ...mapGetters('users', {
      salesManagerList: 'salesManagerList',
      processEngineerList: 'processEngineerList'
    }),
    ...mapGetters('records', {
      propertiesAll: 'propertiesAll',
      timings: 'timingAll',
      delayReasons: 'delayReasonAll'
    }),
    ...mapGetters('permission', {
      isAdmin: 'isAdmin'
    }),

    isExternalOrder () {
      return !!this.orderById?.external_id
    },

    isDisabledForExternal () {
      return this.isDisabled || this.isExternalOrder
    },

    isReclamationOrder () {
      return !!this.orderById?.is_outer_reclamation
    }
  },

  created () {
    this.isEdit = !!this.$route.params.id
  },

  async mounted () {
    if (!this.isEdit) return

    await this.getOrderOne({ id: +this.$route.params.id })
    this.getFormData()
  },

  watch: {
    'formData.timing' (val) {
      if (!this.isEdit && val) {
        this.getDateFinished(val)
      }
    }
  },

  methods: {
    ...mapActions({
      createOrder: 'order/createOrder',
      updateOrder: 'order/updateOrder',
      getOrderOne: 'orderDetail/getOrderOne',
      setAlertSuccess: 'alert/setAlertSuccess',
      setAlertError: 'alert/setAlertError'
    }),
    goToPrevPage,

    getFormData () {
      // eslint-disable-next-line camelcase
      const tech_manager = {
        ...this.orderById.tech_manager,
        name: getUserFullName(this.orderById.tech_manager)
      }
      const manager = {
        ...this.orderById.manager,
        name: getUserFullName(this.orderById.manager)
      }

      this.formData = {
        ...this.orderById,
        tech_manager,
        manager,
        application_area: this.orderById?.application_area_d
      }
    },

    async getDateFinished (data) {
      try {
        const res = await getAll('order/date-finished', {
          timing_id: data.id
        })

        this.formData.date_finished = res?.data[0]
      } catch (e) {
        await this.setAlertError('Не удалось загрузить дату отгрузки заказа')
      }
    },

    checkRelationOrders (value) {
      this.clearTimeoutId()
      if (!value.length) return this.finishAwaitGetRelationOrders()

      this.startAwaitGetRelationOrders()
      this.setTimeoutId(() => this.getRelationOrders(value), 500)
    },

    async getRelationOrders (value) {
      try {
        this.startLoading()
        const accountNumbers = splitRelationOrders(value)

        const response = await Promise.all(accountNumbers
          // eslint-disable-next-line camelcase
          .map(account_number => OrderController.getAll({ account_number }))
        )
        this.relationOrdersResponse = response
      } catch (e) {
        await this.setAlertError('Ошибка при поиске связанных заказов')
      } finally {
        this.finishLoading()
        this.finishAwaitGetRelationOrders()
      }
    },

    startAwaitGetRelationOrders () {
      this.isAwaitGetRelationOrders = true
    },
    finishAwaitGetRelationOrders () {
      this.isAwaitGetRelationOrders = false
    },

    prepareFormData (data) {
      const relationOrderIds = []
      for (const response of this.relationOrdersResponse) {
        const order = response?.data?.at(0)
        if (!order) continue
        relationOrderIds.push(order.id)
      }
      data.relation_orders_ids = relationOrderIds.length ? relationOrderIds : this.orderById?.relation_orders_ids

      const keyList = [
        'application_area',
        'type',
        'timing',
        'manager',
        'tech_manager',
        'delay_reason'
      ]

      return prepareFormDataId(data, keyList)
    },

    async submitCreateOrder (data) {
      try {
        this.loading = true
        const postData = this.prepareFormData(data)

        let response = null

        if (this.isEdit) {
          response = await this.updateOrder(postData)
        } else {
          postData.date_order = new Date()
          postData.date_finished = null

          response = await this.createOrder(postData)
        }

        if (!response?.id) return

        await this.setAlertSuccess('Заказ сохранен')
        await goToPage('OrderDetails', { id: response.id })
      } finally {
        this.loading = false
      }
    },
    changeDateFinished (val) {
      if (this.isReclamationOrder && val) {
        this.getDateFinished(val)
      }
    }
  }
}
</script>

<style lang="scss">
.input__field_textarea {
  height: 110px;
}
.form {
  display: flex;
  align-items: flex-start;

  .form__col {
    width: 380px;

    &_text {
      width: 50%;
    }
  }
  .form__col +  .form__col {
    margin-left: 20px;
  }
  .input + .input {
    margin-top: 20px;
  }
}

.wrap-form {
  background: var(--bg-color__box);
  box-shadow: inset -4px -4px 6px rgba(249, 251, 255, 0.29), inset 0px 4px 11px rgba(209, 217, 230, 0.44);
  border-radius: 20px;
  padding: 20px 25px 30px;
  width: 100%;
}
</style>
